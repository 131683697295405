@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"




























.loading-spinner
  display: flex
  flex-flow: column
  align-items: center
  justify-content: center

  p
    font-size: 24px
    padding-top: 20px
