@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"




























































body
  padding: 0
  margin: 0
  background: $color-ice

.app-main
  &__loading
    background: $color_ice
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: calc(100vh - #{$menu-height})
    position: fixed
    top: $menu-height
    left: 0
    z-index: 9

  &__menu
    margin-bottom: $menu-height

    .content-menu
      z-index: 10
