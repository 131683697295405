// -------------------------------------------------------------------
// Z-INDEX
// -------------------------------------------------------------------
$z-back: -10
$z-middle: 1
$z-front: 10

// -------------------------------------------------------------------
// DIMENSIONS
// -------------------------------------------------------------------
$end-space: 3 * $size-xxl
$menu-height: 70px

// -------------------------------------------------------------------
// QUESTION
// -------------------------------------------------------------------
$question-max-width: 800px
$mission-max-width: 778px